<template>
    <div>
        <div class="text-center">
            <img src="../../../assets/user-home/image.png">
        </div>
        <div class="bgc-danger-100">
            <p class="text-center text-danger font-proxima-bold py-2">Error!User has a valid subscription</p>
        </div>
        <div class="text-center">
            <btn text="Back to home" :border-radius="1" color="success"></btn>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PaymentModal'
};
</script>

<style scoped>

</style>
